<template>
  <!-- 添加服务项目/家电 -->
  <div class="container">
    <a-page-header
      :title="title"
      :sub-title="subTitle"
      @back="() => $router.go(-1)"
    />

    <div class="main-content">
      <div class="body">
        <a-steps type="navigation" :current="stepsCurrent">
          <a-step
            v-for="item in steps"
            :key="item.title"
            :title="item.title"
            :description="item.description"
          />
        </a-steps>

        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 6 }"
          v-if="stepsCurrent === 0"
        >
          <a-form-model-item
            label="选择要添加的服务类型"
            prop="address"
            v-if="stepsCurrent === 0"
          >
            <a-select
              allow-clear
              placeholder="请选择要添加的服务类型"
              v-model="form.categoryId"
            >
              <a-select-option v-for="d in selectData" :key="d.id">
                {{ d.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item class="submit-btn">
            <a-button type="primary" @click="addMainService">
              添加并继续
            </a-button>
          </a-form-model-item>
        </a-form-model>

        <template v-if="stepsCurrent === 1">
          <my-table
            :columns="columns"
            :data-source="dataSource"
            :loading="loading"
            :pagination="false"
            @expand="handleExpand"
          >
            <template slot="cover" slot-scope="record">
              <div class="cover">
                <img class="img" :src="record.cover" alt="cover" />
              </div>
            </template>

            <a-table
              class="inner-table"
              slot="expandedRowRender"
              slot-scope="innerRecord"
              :columns="innerColumns"
              :data-source="innerRecord.data"
              :pagination="false"
              :row-key="(record) => record.id"
              :loading="expandedLoading"
              :row-selection="rowSelection"
            >
              <template slot="cover" slot-scope="records">
                <div class="cover">
                  <img class="img" :src="records.cover" alt="cover" />
                </div>
              </template>
              <template slot="price" slot-scope="records">
                <a-statistic prefix="￥" :value="records.price" />
              </template>
            </a-table>
          </my-table>

          <div class="add-button-box">
            <a-button type="primary" @click="addMainServiceEquipment">
              确认添加
            </a-button>
            <div class="text-box">
              <a-icon type="info-circle" />
              <span class="text">默认家电设备为下架状态，添加后自行上架</span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
let innerDataIndex = -1
let serviceEquipment = []
export default {
  data() {
    return {
      title: '',
      subTitle: '',
      stepsCurrent: 0,
      steps: [
        {
          title: '选择要添加的服务类型'
        },
        {
          title: '选择服务项目/家电设备'
        }
      ],
      form: {
        bcid: 1,
        categoryId: undefined
      },
      rules: {
        categoryId: [
          {
            required: true,
            message: '请选择要添加的服务类型',
            trigger: 'change'
          }
        ]
      },
      selectData: [],
      dataSource: [
        {
          id: 1,
          categoryId: 1,
          cover:
            'http://bufansimage.shanghu918.com/FlqlwhtMIyG3QGs_hi607KIGSF1N',
          heading: '7',
          sort: 12,
          data: [
            {
              id: 10,
              servingId: 1,
              heading: '测试1',
              cover: 'https://qiniu.aliu6.com/FiVcTWkzDFhE5grwDAcGHtMxhMRJ',
              subtitle: '测试3',
              sort: 1
            },
            {
              id: 12,
              servingId: 1,
              heading: '测试2',
              cover: 'https://qiniu.aliu6.com/FiVcTWkzDFhE5grwDAcGHtMxhMRJ',
              subtitle: '测试3',
              sort: 2
            },
            {
              id: 13,
              servingId: 1,
              heading: '测试3',
              cover: 'https://qiniu.aliu6.com/FiVcTWkzDFhE5grwDAcGHtMxhMRJ',
              subtitle: '测试3',
              sort: 3
            }
          ]
        },
        {
          id: 2,
          categoryId: 1,
          cover:
            'http://bufansimage.shanghu918.com/FlqlwhtMIyG3QGs_hi607KIGSF1N',
          heading: '7',
          sort: 12
        },
        {
          id: 3,
          categoryId: 1,
          cover:
            'http://bufansimage.shanghu918.com/FvjOMcPA04hb9lsrgbATXG86BsFN',
          heading: 'cs',
          sort: 12
        },
        {
          id: 4,
          categoryId: 1,
          cover:
            'http://bufansimage.shanghu918.com/Fh75olpoRYGWKOA8cf83uBCUF_Cw',
          heading: 'cs',
          sort: 1
        },
        {
          id: 6,
          categoryId: 1,
          cover:
            'http://bufansimage.shanghu918.com/FrcRAyEGcHy5EY9oyeGipSj4QzfA',
          heading: 'cs',
          sort: 2
        }
      ],
      columns: [
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '服务名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '缩略图',
          scopedSlots: { customRender: 'cover' }
        }
      ],
      innerColumns: [
        {
          title: '排序',
          dataIndex: 'sort',
          width: 60
        },
        {
          title: '设备名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '设备属性',
          dataIndex: 'subtitle',
          width: 300
        },
        {
          title: '缩略图',
          width: 100,
          scopedSlots: { customRender: 'cover' }
        },
        {
          title: '默认售价',
          scopedSlots: { customRender: 'price' }
        }
      ],
      rowSelection: {
        onChange: this.onEquipmentChange
      },
      loading: true,
      expandedLoading: true
    }
  },
  created() {
    this.title = this.$getPageTitle()
    const query = this.$route.query
    this.subTitle = query.name
    this.getNotPunlicService()
  },
  methods: {
    getNotPunlicService() {
      // 获取公共数据源服务类型
      this.$axios.getNotPunlicService().then((res) => {
        this.selectData = res.data.data
      })
    },
    addMainService() {
      // 添加分类并进入下一步
      this.$axios.addMainService(this.form).then(() => {
        this.getPublicServiceItems()
        this.stepsCurrent++
      })
    },
    getPublicServiceItems() {
      // 获取公共数据服务项目
      const data = {
        categoryId: this.form.categoryId
      }
      this.$axios.getPublicServiceItems(data).then((res) => {
        this.dataSource = res.data.data
        this.loading = false
      })
    },
    getPublicServiceItemsEquipment() {
      // 获取公共数据服务项目设备
      const dataSource = this.dataSource[innerDataIndex]
      const data = {
        servingId: dataSource.id
      }
      this.$axios.getPublicServiceItemsEquipment(data).then((res) => {
        dataSource.data = res.data.data
        this.dataSource.splice(innerDataIndex, 1, dataSource)
        this.expandedLoading = false
      })
    },
    handleExpand(e, r) {
      // 监听表格展开
      this.expandedLoading = e
      if (e) {
        innerDataIndex = this.dataSource.findIndex((f) => f.id === r.id)
        this.getPublicServiceItemsEquipment()
      }
    },
    onEquipmentChange(selectedRowKeys, selectedRows) {
      // console.log(selectedRowKeys)
      // console.log(selectedRows)
      selectedRows.forEach((e) => {
        const item = {
          servingId: e.categoryId,
          infoId: e.id
        }
        serviceEquipment.push(item)
      })
    },
    addMainServiceEquipment() {
      // 添加对应分类的设备
      if (!serviceEquipment.length) {
        this.$message.error('请先选择要添加的设备')
        return
      }
      const data = {
        bcid: 1,
        categoryId: this.form.categoryId,
        Info: serviceEquipment
      }
      this.$axios.addMainServiceEquipment(data).then(() => {
        this.$message.success('添加成功')
        serviceEquipment = []
      })
    }
  }
}
</script>

<style scoped>
.body {
  height: 100%;
  background-color: #fff;
  padding: 48px 0;
}

.ant-steps,
.ant-form {
  width: 1050px;
  margin: 0 auto;
}

.ant-form {
  margin-top: 110px;
}

.ant-form .ant-btn {
  margin-left: 25em;
}

.table-wrap {
  margin-top: 8px;
}

.cover {
  width: 50px;
  height: 50px;
}

.ant-table-wrapper {
  border: 1px solid #ebebeb;
}

.inner-table {
  background-color: #fafafa;
}

.add-button-box {
  display: flex;
  align-items: center;
  margin-top: 32px;
}

.add-button-box .anticon {
  margin: 0 4px 0 16px;
}

.add-button-box .text {
  font-weight: 500;
  color: #595959;
}
</style>
